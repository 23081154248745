import {
  DividerBlock,
  Structure,
  TextBlock,
} from '../../core-email-builder/src/public_api';

export const defaultModules = [
  {
    name: 'footer',
    module: new Structure('cols_1', [
      [
        new DividerBlock({
          border: {
            color: '#909090',
            style: 'solid',
            width: 1,
          },
          padding: {
            top: 10,
            left: 25,
            right: 25,
            bottom: 10,
          },
        }),
        new TextBlock(
          '<p class="ql-align-center">Address: {enter address} | Phone: {enter phone}</p>',
          {
            font: {
              family: 'Roboto',
              size: 14,
              fallback: 'Arial, Helvetica, sans-serif',
            },
            color: '#000000',
            padding: {
              top: 10,
              left: 25,
              right: 25,
              bottom: 10,
            },
            lineHeight: {
              unit: 'px',
              value: 40,
            },
          }
        ),
        new TextBlock(
          '<p class="ql-align-center"><a href="[webversion]" rel="noopener noreferrer" target="_blank">view on the web</a></p><p id="njea-unsubscribe" class="ql-align-center"><a href="[unsubscribe]" rel="noopener noreferrer" target="_blank">unsubscribe</a></p>',
          {
            font: {
              size: 14,
              family: 'Roboto',
              fallback: 'Arial, Helvetica, sans-serif',
            },
            color: '#000000',
            padding: {
              top: 10,
              left: 25,
              right: 25,
              bottom: 10,
            },
            lineHeight: {
              unit: 'px',
              value: 40,
            },
          }
        ),
      ],
    ]),
  },
  {
    name: 'unsubscribe link',
    module: new Structure('cols_1', [
      [
        new TextBlock(
          '<p id="njea-unsubscribe" class="ql-align-center"><a href="[unsubscribe]" rel="noopener noreferrer" target="_blank">unsubscribe</a></p>',
          {
            font: {
              family: 'Roboto',
              size: 14,
              fallback: 'Arial, Helvetica, sans-serif',
            },
            color: '#000000',
            padding: {
              top: 10,
              left: 25,
              right: 25,
              bottom: 10,
            },
            lineHeight: {
              unit: 'px',
              value: 40,
            },
          }
        ),
      ],
    ]),
  },
];
